<template>
  <div class="misc-wrapper" 
  >
    <b-link class="brand-logo">
      <img src="@/assets/images/logo/logo_login.png" height="50" alt="">
    </b-link>
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center" style="padding: 5px 0;
    border-radius: 5px;
    background-color: white;">
        <b-img
          fluid
          :src="imgUrl"
          alt="Not authorized page"
        />
        <h2 class="mb-1 mt-1">
          {{$t('auth.not_authorized')}} 🔐
        </h2>
        <p class="mb-2">
          {{$t('auth.not_authorized_description')}}
        </p>
        <b-button
          variant="primary"
          class="mb-1 btn-sm-block"
          :to="{path:'/'}"
        >{{$t('auth.back_to_sl')}}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg, BButton } from 'bootstrap-vue'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    BLink, BImg, BButton,
  },
  data() {
    return {
      downImg: require('@/assets/images/logo/sad_seal.jpg'),
      //downImg: require('@/assets/images/pages/not-authorized.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        //this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  methods: {
    loginRoute() {
      const user = this.$store.getters['user/getUserData']
      return getHomeRouteForLoggedInUser(user ? user.role : null)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
